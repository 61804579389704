import React from "react";
import { useNavigate } from "react-router-dom";

function TopBanner({ imgBg, center }) {

  const navigate = useNavigate();
  return (
    <section className="w-full h-auto max-md:min-h-[400px]" style={{
      width: "100%",
      backgroundImage: `url(${imgBg})`,
      height: "250px",
      margin: "auto",
      zIndex: "-1",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
    }}>
      <div
        className={`${
          center ? "min-[768px]:ml-[40%]" : "min-[768px]:ml-auto"
        } max-w-[1200px] items-center justify-center m-auto h-[300px] max-xl:flex max-xl:flex-col max-xl:justify-center max-xl:items-center`}
      >
        <div className="max-sm:text-sm20 max-lg:text-[24px] min-[1279px]:pt-14 max-md:pt-20 text-[33px] font-bold  text-white">
          GOVERNANCE IN PREVENTING FRAUD​
        </div>
        <div className="max-sm:text-center text-white font-light text-sm15 max-sm:mt-2">
          Dedicated to implementing robust fraud prevention strategies.
        </div>
        <button onClick={() => navigate("/register")} className="bg-[#e0e7ff] px-4 rounded-full text-sm15 py-2 font-semibold mt-5 text-blue-900">
          Get Verified Now!
        </button>
      </div>
    </section>
  );
}

export default TopBanner;