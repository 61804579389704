import React from "react";
import TopBanner from "../components/TopBanner";
import BecomeRecognized from "../components/BecomeRecognized";

function PrivacyMain() {
  return (
    <div className="bg-[#F9FAFB]">
      <div className="max-md:hidden">
        <TopBanner imgBg={"/PrivacyPolicyHeader-BG.png"} center={false} />
      </div>
      <div className="hidden max-md:block">
        <TopBanner imgBg={"/PrivacyPolicyHeader-BGMobile.png"} center={true} />
      </div>
      <div className="max-w-main m-auto px-12 max-md:px-5 mt-16">
        <div className="w-full flex overflow-hidden gap-2 items-center">
          <div className="flex flex-col justify-center self-stretch my-auto w-full max-md:max-w-full">
            <div className="flex flex-col justify-center w-full max-md:max-w-full">
              <div className="text-3xl font-bold max-md:text-[20px] tracking-tight leading-9 text-blue-900 max-md:max-w-full">
                PRIVACY POLICY
              </div>
              <div className="flex flex-col self-stretch mt-6 px-5 py-12 max-md:py-5 max-md:px-0 bg-white rounded-xl border border-solid">
                <div className="flex flex-col px-5 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    1. Introduction
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    At Blockchain Management Corporation Pty Ltd (&quot;we,&quot;
                    &quot;our,&quot; or &quot;us&quot;), your privacy and the
                    security of your personal information are our top priorities.
                    This Privacy Policy explains how we collect, use, store, and
                    protect your information in compliance with the strictest
                    privacy laws, including:
                  </div>
                  <div className="px-6 mt-4 w-full font-bold max-md:px-5 max-md:max-w-full">
                    <ul className="list-disc pl-7">
                      <li>
                        General Data Protection Regulation (GDPR) (European Union),
                      </li>
                      <li>
                        California Consumer Privacy Act (CCPA) (United States), and
                      </li>
                      <li>
                        Privacy Act (Australia)
                      </li>
                    </ul>
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    By providing your information or using our services, you agree
                    to the terms of this Privacy Policy. If you do not agree, you
                    must discontinue using our services. This policy applies
                    exclusively to the operations of <a href="https://devreg.org" className="underline" target="_blank" rel="noreferrer"> https://devreg.org </a> and is governed
                    by Blockchain Management Corporation Pty Ltd, which owns and operates the
                    platform.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    2. Information We Collect
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We collect the following types of information as part of our
                    verification process and to maintain the integrity of our
                    platform:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      2.1 Personal Identification Information
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>Full name</li>
                        <li>Address</li>
                        <li>Date of birth</li>
                        <li>Email</li>
                        <li>Phone number</li>
                        <li>Government-issued IDs (e.g., passports, driver’s licenses)</li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      2.2 Verification Data
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>Facial recognition data</li>
                        <li>Live interview recordings</li>
                        <li>Uploaded documents required for identity verification</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      2.3 Profile Data
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Any additional information you voluntarily provide to your
                          public profile, such as professional skills or job details.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      2.4 Activity Data
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Records of your interactions with our platform, including logs
                          of searches and profile updates.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We ensure that only the minimum necessary information is
                    collected to provide our services and meet our legal
                    obligations.
                  </div>
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    3. Purpose of Data Collection
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We collect and retain data for the following purposes:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.1 Identity Verification
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          To confirm your identity and ensure compliance with our
                          verification standards.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.2 Fraud Prevention
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          To protect the integrity of our platform and prevent
                          fraudulent activities.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.3 Operational Integrity
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          To comply with legal, regulatory, and operational
                          requirements.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      3.4 User Directory
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          To display basic details (excluding profile photos) for client
                          searches.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    4. Profile Photos
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    <ul className="list-disc pl-7">
                      <li>Your profile photo is not publicly visible.</li>
                      <li>The photo is securely embedded within your account and can only be accessed by the CEO through a secure portal.</li>
                      <li>
                        Purpose: The CEO may review your original photo to ensure that the uploaded profile photo matches your identity and has not been replaced with someone else’s photo.
                      </li>
                      <li>
                        Storage: Profile photos and other sensitive verification data are stored securely on an encrypted offline server that is never connected to the internet.
                      </li>
                    </ul>
                    This ensures the highest level of privacy and security.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    5. Data Retention Policy
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We retain all data indefinitely for the following reasons:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.1 Fraud Prevention
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Retaining data ensures we can verify and investigate any
                          suspected fraudulent activity involving a user.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.2 Compliance with Regulations
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Data retention is necessary to demonstrate compliance with
                          identity verification and anti-fraud standards.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      5.3 Operational Integrity
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Deleting records undermines the credibility and security of
                          our network, as identity re-verification would be impossible.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    6. Data Deletion Requests
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We do not delete verification data, except in the following
                    circumstances:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      6.1 Legal Obligation
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          If required by a court order or regulatory authority.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      6.2 Extraordinary Circumstances
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      If deletion is deemed absolutely necessary and approved by our
                      compliance team, this will result in the following:
                      <br />
                      <ul className="list-disc pl-7">
                        <li>Permanent deletion of all associated data.</li>
                        <li>Termination of your account with no refund.</li>
                        <li>
                          Permanent ban from our platform to protect the integrity of our network.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    7. Data Security
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We implement the highest standards of security to protect your
                    information:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.1 Offline Storage
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          All sensitive information is stored on an encrypted offline
                          server that is never connected to the internet.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.2 Access Control
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Only the CEO, using biometric authentication (fingerprint and
                          iris scan) and password-protected encryption, can access
                          stored data.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.3 File Identification
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Data files are identified solely by a serial number to
                          maintain confidentiality.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.4 Encryption
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          Files are stored in password-protected encrypted archives.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="gap-10 w-full text-2xl font-semibold tracking-tight leading-tight text-neutral-600 max-md:max-w-full">
                      7.5 Data Breach Protocol
                    </div>
                    <div className="mt-4 text-base leading-8 text-zinc-600 max-md:max-w-full">
                      <ul className="list-disc pl-7">
                        <li>
                          In the unlikely event of a data breach, we will notify
                          affected individuals and relevant authorities as required by
                          law. This includes a detailed report on the scope of the
                          breach, the data affected, and steps taken to mitigate risks.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    8. Publicly Visible Information
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    The following information is displayed publicly on your profile:
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <ul className="list-disc pl-7">
                      <li>
                        First and last name
                      </li>
                      <li>
                        Any additional information you voluntarily add to your profile
                      </li>
                    </ul>
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    Your profile photo and other sensitive verification data are not
                    publicly visible and are stored securely on an offline server
                    that is never connected to the internet. These measures are in
                    place to ensure the highest level of privacy and security.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    9. Complaint Handling
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    If you have concerns about your data or this policy, you may:
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <ul className="list-decimal pl-7">
                      <li>
                        Contact us directly at compliance@devreg.com
                      </li>
                      <li>
                        File a complaint with your local data protection authority
                        (e.g., GDPR authorities, OAIC in Australia).
                      </li>
                    </ul>
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    10. Cross-Border Data Handling
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    Your data is securely stored within Australia. We do not
                    transfer or process your data outside of Australia. If courts or
                    legal authorities outside Australia require access to your data,
                    they must apply through Australian courts. No data will be
                    provided directly to foreign entities under any circumstances.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    11. Children’s Privacy
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    Our services are available to individuals aged 16 and above, in
                    line with Australian work regulations. For individuals under the
                    age of 18, we require parental or guardian consent. Parents or
                    guardians must also register and complete the verification
                    process alongside the minor.
                    <br />
                    If we become aware that data has been collected from a child
                    under the age of 16 without parental consent, we will take
                    immediate steps to delete the data.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full text-base leading-8 text-zinc-600 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    12. Justification for Data Retention
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    We believe that retaining your data indefinitely is critical
                    for:
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full">
                      <div className="max-md:max-w-full">
                        Fraud Detection and Prevention
                      </div>
                      <div className="flex-1 shrink gap-2.5 self-stretch mt-1 w-full max-md:max-w-full">
                        <ul className="list-disc pl-7">
                          <li>
                            Ensuring the platform remains a safe and secure space for
                            all users.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full">
                      <div className="max-md:max-w-full">
                        Operational Compliance
                      </div>
                      <div className="flex-1 shrink gap-2.5 self-stretch mt-1 w-full max-md:max-w-full">
                        <ul className="list-disc pl-7">
                          <li>
                            Meeting legal and regulatory obligations.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full">
                      <div className="max-md:max-w-full">Network Integrity</div>
                      <div className="flex-1 shrink gap-2.5 self-stretch px-6 mt-1 w-full max-md:px-5 max-md:max-w-full">
                        <ul className="list-disc pl-7">
                          <li>
                            Avoiding potential vulnerabilities introduced by data
                            deletion, which could lead to identity fraud or misuse.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 mt-4 w-full max-md:px-5 max-md:max-w-full">
                    This policy aligns with exceptions under GDPR Article 17, CCPA,
                    and Australia’s Privacy Act, which allow for data retention when
                    necessary for fraud prevention, compliance, or legal
                    obligations.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    13. Updates to This Policy
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    We reserve the right to update this Privacy Policy to reflect
                    changes in our practices or legal requirements. Users will be
                    notified of updates via email or platform notifications.
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
                <div className="flex flex-col px-5 mt-4 w-full max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-tight text-indigo-950">
                    14. Contact Us
                  </div>
                  <div className="px-6 mt-4 w-full text-base leading-8 text-zinc-600 max-md:px-5 max-md:max-w-full">
                    If you have any questions or require assistance, please contact
                    us at:<br />Email: compliance@devreg.com<br />Address: Ground Floor,
                    470 St Kilda Road, Melbourne, Victoria 3004, Australia
                  </div>
                  <div className="mt-4 w-full min-h-0 border-b border-solid border-zinc-200 max-md:max-w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BecomeRecognized />
    </div>
  );
}

export default PrivacyMain;