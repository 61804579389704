import { useEffect, useState } from "react";
import React from "react";
import BannerProfile from "../components/BannerProfile";
import { getMyReferrals } from "../../../api/apiCall.js";
import axios from 'axios';
import { FiCopy } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";

function EarningsAndReferrals() {
  const [referrals, setReferrals] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  useEffect(() => {
    console.log("Component mounted");
    fetchReferrals();
    return () => {
      console.log("Component unmounted");
    };
  }, []);

  const fetchReferrals = async () => {
    try {
      let data = {
        id: user.id,
        token: token
      }
      console.log("fetchReferrals called");
      const response = await getMyReferrals(data);
      console.log("API response:", response);
      setReferrals(response.data);
    }
    catch (error) {
      console.error("Error fetching referrals:", error.response.data);
    }
  };

  const copyReferalLink = async () => {
    try {
      await navigator.clipboard.writeText(referrals.referral_link + '&t=c');
      alert('Recovery codes copied to clipboard!');
    }
    catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const shareReferralLink = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Refer and Earn',
          text: 'Check out this referral link!',
          url: referrals.referral_link + '&t=s',
        });
        console.log('Successful share');
      } catch (error) {
        console.log('Error sharing', error);
      }
    }
    else {
      alert('Share not supported on this browser, copy the link instead');
    }
  };

  return (
    <div className="min-h-[89vh] pt-6 max-md:pt-0">
      <div className="max-md:px-3">
        <BannerProfile />
        <div className="flex flex-col mt-5 w-full max-md:max-w-full">
          <div className="flex flex-col w-full max-md:max-w-full">
            <h2 className="text-sm font-medium text-gray-600">Refer and Earn</h2>
            <div className="flex flex-wrap gap-2 items-center mt-1 max-w-full w-[497px]">
              <input
                type="text"
                readOnly
                value={referrals.referral_link}
                aria-label="Referral link"
                className="gap-2 self-stretch px-4 py-2 my-auto text-sm text-gray-500 whitespace-nowrap rounded-lg border border-gray-400 border-solid min-w-[240px] w-[370px] max-md:w-[75%]"
              />
              <button onClick={copyReferalLink}
                aria-label="Copy referral link"
                className="flex justify-center items-center p-2 w-9 h-9 rounded-lg border border-blue-600 border-solid bg-blue-600 bg-opacity-10"
              >
                <FiCopy size={20} className="text-blue-600" />
              </button>
              <button
                onClick={shareReferralLink}
                aria-label="Share referral link"
                className="flex justify-center items-center p-2 w-9 h-9 rounded-lg border border-blue-600 border-solid bg-blue-600 bg-opacity-10"
              >
                <IoShareSocialOutline className="text-blue-600" />
              </button>
            </div>
          </div>

          {/* Referrals and Stats Section */}
          <div className="flex flex-col p-6 my-8 w-full bg-white rounded-2xl border border border-solid max-md:px-5 max-md:max-w-full">
            <h2 className="gap-4 pb-4 w-full text-base font-bold text-gray-600 border-b border-solid max-md:max-w-full">
              My Referrals
            </h2>

            <div className="flex flex-wrap gap-4 mt-4 w-full max-md:max-w-full max-md:justify-between">
              <div
                className="flex flex-col max-md:w-[25%] justify-center items-center p-4 whitespace-nowrap bg-gray-200 rounded-lg border border-gray-500 border-solid"
              >
                <div className="text-sm font-medium text-gray-600">
                  Referrals
                </div>
                <div className="text-xl font-bold text-sky-600">{referrals.referrals}</div>
              </div>
              <div
                className="flex flex-col max-md:w-[40%] justify-center items-center p-4 whitespace-nowrap bg-gray-200 rounded-lg border border-gray-500 border-solid"
              >
                <div className="text-sm font-medium text-gray-600">
                  Badge Draw
                </div>
                <div className="text-xl font-bold text-sky-600">{referrals.badge_draw_amount}</div>
              </div>
              <div
                className="flex flex-col max-md:w-[25%] justify-center items-center p-4 whitespace-nowrap bg-gray-200 rounded-lg border border-gray-500 border-solid"
              >
                <div className="text-sm font-medium text-gray-600">
                  Total
                </div>
                <div className="text-xl font-bold text-sky-600">{referrals.referral_amount}</div>
              </div>
              <div className="flex flex-col justify-center items-center p-4 my-auto text-white bg-sky-600 rounded-lg max-md:w-full">
                <div className="text-sm font-medium">Total Earned</div>
                <div className="text-2xl font-bold">{referrals.total_earned}</div>
              </div>
            </div>

            {/* Terms and Conditions */}
            <div className="flex flex-col pt-4 mt-4 w-full text-sm border-t border-solid max-md:max-w-full">
              <h3 className="font-semibold text-gray-600">Terms and Conditions</h3>
              <p className="mt-2 leading-5 text-gray-500 max-md:max-w-full">
                <strong>1.Eligibility</strong><br />
                <div className="ml-2">
                  1.1. The badge draw is open to all verified members across all linked websites.<br />
                  1.2. Members must have completed the identity verification process and be in good financial standing to claim the prize.<br />
                  1.3. Members listed as unfinancial are ineligible to claim the jackpot until their financial status is restored.<br />
                </div>

                <strong>2. Automatic Entry</strong><br />
                <div className="ml-2">
                  2.1. All eligible members are automatically entered into the badge draw each month.<br />
                  2.2. No further action is required to participate in the draw.<br />
                </div>

                <strong>3. Draw Details</strong><br />
                <div className="ml-2">
                  3.1. The badge draw occurs on the last day of each month.<br />
                  3.2. One winner is randomly selected from the pool of eligible members.<br />
                  3.3. If the selected member is unfinancial, the jackpot amount rolls over to the following month until a financial member wins.<br />
                  3.4. The winner will be notified only through their dashboard.<br />
                </div>

                <strong>4. Prize</strong><br />
                <div className="ml-2">
                  4.1. The jackpot prize starts at a base amount $100.00 UDS and accumulates monthly until a financial member claims it.<br />
                  4.2. Once a financial member is selected, the prize is automatically credited to the member’s account in the portal and can be used at their discretion.<br />
                  4.3. In the event of the member’s death, the funds in the account will be transferred to the nominated next of kin (NOK) as provided on the member's NOK page.<br />
                  4.4. Once a financial member claims the prize, the jackpot resets to the base amount for the next month’s draw.<br />
                </div>

                <strong>5. Claiming the Prize</strong><br />
                <div className="ml-2">
                  5.1. The prize is automatically added to the winner's account in the portal upon selection.<br />
                  5.2. There is no requirement for the winner to acknowledge the prize; it is added to their account regardless of whether they take any action. They may withdraw the funds by contacting the admin through the portal, providing correct bank account details.<br />
                  5.3. If incorrect bank account details are provided and the payment is sent to the wrong person, neither the admin nor the website will be held liable.<br />
                </div>

                <strong>6. Referral Bonuses</strong><br />
                <div className="ml-2">
                  6.1. Members can earn a bonus for referring applicants who complete the registration and verification process.<br />
                  6.2. Referral bonuses are credited to the referrer’s account once the new member’s account is fully verified.<br />
                  6.3. Referral bonuses are non-transferable and is for the account member only.<br />
                </div>

                <strong>7. Administrator Discretion</strong><br />
                <div className="ml-2">
                  7.1. The administrator reserves full discretion in managing the badge draw and determining eligibility based on financial status.<br />
                  7.2. The administrator’s decisions regarding the draw and prize distribution are final.<br />
                </div>

                <strong>8. General Provisions</strong><br />
                <div className="ml-2">
                  8.1. The badge draw terms and conditions apply across all linked websites and are open to all members.<br />
                  8.2. These terms may be modified or updated at any time without prior notice.<br />
                </div>

                <strong>9. Limitation of Liability</strong><br />
                <div className="ml-2">
                  9.1. The websites, administrators, and their affiliates are not responsible for any losses, damages, or claims arising out of or in connection with participation in the badge draw, including but not limited to any technical issues, system errors, or delays in processing entries or prize claims.<br />
                  9.2. In the event of any system malfunction, error, or fraud, the administrator reserves the right to cancel, modify, or suspend the badge draw without prior notice.<br />
                  9.3. The website and administrators will not be liable for any incorrect or incomplete information provided by the member during the account setup or prize withdrawal process. Members are responsible for ensuring the accuracy of their personal and financial details.<br />
                </div>

                <strong>10. Dispute Resolution</strong><br />
                <div className="ml-2">
                  10.1. Any disputes arising out of or related to the badge draw will be resolved in accordance with the laws of the jurisdiction where the company is registered.<br />
                  10.2. Members agree to attempt to resolve any disputes informally by contacting the administrator through the portal before pursuing any legal action.<br />
                </div>

                <strong>11. Force Majeure</strong>
                <div className="ml-2">
                  11.1. The website and administrators shall not be held liable for any delay or failure in performance caused by circumstances beyond their reasonable control, including but not limited to acts of God, war, natural disasters, or other unforeseen events.<br />
                </div>

                <strong>12. Privacy and Data Security</strong><br />
                <div className="ml-2">
                  12.1. The personal and financial information provided by members for the purpose of participating in the badge draw will be handled in accordance with our Privacy Policy.<br />
                  12.2. We take reasonable steps to protect the personal data of members, but we are not liable for any unauthorized access, disclosure, or loss of personal data unless caused by our gross negligence or willful misconduct.<br />
                </div>

                <strong>13. Termination of Membership</strong><br />
                <div className="ml-2">
                  13.1. The administrator reserves the right to terminate any member's participation in the badge draw if they breach these terms and conditions, provide false or misleading information, or engage in any fraudulent activity.<br />
                  13.2. Terminated members will forfeit any claim to current or future prizes from the badge draw.<br />
                </div>

                <strong>14. Non-Transferability of Prizes</strong><br />
                <div className="ml-2">
                  14.1. Prizes awarded through the badge draw are non-transferable and must be claimed by the verified member or their nominated next of kin (NOK) in the event of the member’s death.<br />
                  14.2. The prize cannot be transferred, sold, or assigned to any other third party.<br />
                </div>

                <strong>15. Fraud and Misconduct</strong><br />
                <div className="ml-2">
                  15.1. Any attempt to manipulate or tamper with the badge draw process, including the use of fraudulent information, multiple accounts, or other deceptive methods, will result in disqualification and potential legal action.<br />
                  15.2. The administrator reserves the right to investigate and take appropriate action against any fraudulent activity related to the badge draw.<br />
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarningsAndReferrals;