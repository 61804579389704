import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa6";
const BackButton = ({ buttonFunction, buttonName }) => {
  const navigate = useNavigate();

  const defaultBack = () => {
    navigate(-1);
  };

  const handleClick = buttonFunction || defaultBack;

  return (
    <div className="flex gap-2 text-xl cursor-pointer text-blue-900" onClick={handleClick}>
      <FaChevronLeft className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square cursor-pointer" />
      {buttonName}
    </div>
  );
};

export default BackButton;