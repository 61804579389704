import React from "react";
import TopBanner from "../components/TopBanner";
import ContactUs from "./ContactUs";

function ContactMain() {
  return (
    <div className="bg-[#F9FAFB]">
      <div className="max-md:hidden">
        <TopBanner imgBg={"/ContactPageHeader-BG.png"} center={true} />
      </div>
      <div className="hidden max-md:block">
        <TopBanner imgBg={"/ContactPageHeader-BGMobile.png"} center={true} class="z-1" />
      </div>
      <ContactUs />
    </div>
  );
}

export default ContactMain;