import React from "react";

function WhatWeOffer() {
  const data = [
    {
      img: "/offer1.png",
      title: "Cryptocurrency Developers",
      description:
        "All registered software developers have the freedom to decide how their crypto coin profile will look on https://coinreg.info. You have the option to go public (don’t check the box) or remain anonymous (make sure to check the box). more information will be on the application form with warnings.",
    },
    {
      img: "/offer2.png",
      title: "Registered Badge",
      description:
        "Once registered your badge will link back to your full profile so that your clients can see you are a genuine registered developer, Please use this badge in your emails, and all your social media, if you build websites add it to the bottom bar to show prospective clients that you link this website and they can come to you by clicking on the badge",
    },
    {
      img: "/offer3.png",
      title: "Developer Profile",
      description:
        "A client can access a developer's profile at https://register.dev in one of two ways: (1) by clicking on the registration badge, or (2) by searching for the developer using keywords related to the type of developer they require. When a developer fully completes their profile with a detailed history, it enhances their appeal to potential clients. The more information they include in their profile, the more likely clients will want to hire them.",
    },
  ];

  const data2 = [
    {
      img: "/offer4.png",
      title: "Our Forums",
      description:
        "At Devreg, we offer forums for all our members. If you have questions or need assistance, feel free to seek help there. Likewise, if you have the expertise, you might be able to assist others. We aim to maintain a family-friendly environment in our forums, so please keep discussions respectful and clean.",
    },
    {
      img: "/offer5.png",
      title: "Incentive Diversity",
      description:
        "Hackathon Sponsorships: Funded entries and resources for hackathon participation. Premium Tools: Access to top-tier development tools and services. Tech Rewards: Earn tech gadgets for exceptional contributions. Book Club: Monthly books from our community-driven club. Badge Draws: Win significant cash prizes in our draws.",
    },
    {
      img: "/offer6.png",
      title: "Developer Profile",
      description:
        "COMING SOON: In case of scam incidents carried out by any registered software developer, DevReg will provide an insurance scheme for all Clients in the form of cash payment or replacement of a new software developer (admin discretion will determine the final decision). If a developer scams a client, this will be on record for all to see; we prefer not to have to use the insurance scheme for the developer’s sake.",
    },
  ];
  return (
    <div className="max-w-main m-auto pb-20 max-md:px-3">
      <div className="px-10 font-bold max-md:text-sm20 text-[30px] text-devRegDarkBlue max-md:text-center text-start">
          What We Offer
      </div>
      <div className="flex justify-evenly items-center gap-4 max-md:flex-col">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex mt-10 gap-1 flex-col max-md:justify-center items-start max-md:items-center max-md:px-10 min-[768px]:max-w-[385px] h-[440px] max-[1024px]:h-[520px] max-md:h-auto bg-white rounded-lg p-6 border border-[#E5E7EB]"
          >
            <img src={item.img} className="w-auto h-[60px] " alt={item.title} />
            <div className="font-semibold max-md:text-sm18 text-[24px] text-devRegBlack mt-5">
              {item.title}
            </div>
            <div className="max-sm:text-sm14 text-black70 mt-4">
              {item.description}
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-evenly items-center gap-4 max-md:flex-col">
        {data2.map((item, index) => (
          <div
            key={index}
            className="flex mt-10 gap-1 flex-col max-md:justify-center items-start max-md:items-center max-md:px-10 min-[768px]:max-w-[385px] h-[440px] max-[1024px]:h-[520px] max-md:h-auto bg-white rounded-lg p-6 border border-[#E5E7EB]"
          >
            <img src={item.img} className="w-auto h-[60px] " alt={item.title} />
            <div className="font-semibold max-md:text-sm18 text-[24px] text-devRegBlack mt-5">
              {item.title}
            </div>
            <div className="max-sm:text-sm14 text-black70 mt-4">
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatWeOffer;