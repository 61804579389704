import React from "react";
import { useNavigate } from "react-router-dom";

function FooterDashboard() {
  const navigate = useNavigate();

  const navLinks2 = [
    { text: "Terms & Conditions", to: "/terms-and-conditions" },
    { text: "Anti-Bribery and Corruption Policy", to: "/antibribe-policy" },
    { text: "Privacy Policy", to: "/privacy" },
  ];

  const handleClick = (to) => {
    navigate(to);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="flex justify-center items-center self-stretch px-8 py-2  w-full text-neutral-400 bg-white border-t border-neutral-300 border-solid  max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col flex-1 justify-between max-w-[1366px] max-lg:max-w-full">
            <div className="flex gap-1 text-md max-lg:flex-wrap px-10 max-md:px-0">
              <div className="flex justify-between max-md:flex-col-reverse text-[14px] max-md:text-[10px] w-full flex-row gap-5 py-1.5 leading-[145%] max-lg:flex-wrap max-lg:text-center max-lg:m-auto">
                <div className="">© 2018 Devreg. All Rights Reserved</div>
                <div className="flex items-center gap-7">
                  {navLinks2.map((link, index) => (
                    <div
                      key={index}
                      className="cursor-pointer my-auto "
                      onClick={() => handleClick(link.to)}
                    >
                      {link.text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default FooterDashboard;
