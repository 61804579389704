import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FaqAccordion from "./FaqAccordion";
import {
  listTab,
  generalInformation,
  registrationAndMembership,
  profileManagement,
  verificationProcess,
  privacyAndSecurity,
  supportAndCommunity,
} from "./AccordionFaqs";
import SearchFaqs from "./SearchFaqs";
import BecomeRecognized from "../../components/BecomeRecognized";
import { IoChevronBack } from "react-icons/io5";

function CategoryFaqs() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("general-information");
  const [titleAccordion, setTitleAccordion] = useState("General Information");
  const [activeTab, setActiveTab] = useState("");
  const [overview, setOverView] = useState("general-information");
  const location = useLocation();
  const [accordionData, setAccordionData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get("tab");
  const tabQueryParam = urlParams.get("query");

  useEffect(() => {
    if (location.hash === "#faqsection") {
      document.getElementById("faqsection")?.scrollIntoView({ behavior: "smooth" });
    }

    if (tabParam === "general-information") {
      setOverView("general-information");
      setAccordionData(generalInformation);
      setTitleAccordion("General Information");
    }
    if (tabParam === "registration-and-membership") {
      setOverView("registration-and-membership");
      setAccordionData(registrationAndMembership);
      setTitleAccordion("Registration and Membership");
    }
    if (tabParam === "profile-management") {
      setOverView("profile-management");
      setAccordionData(profileManagement);
      setTitleAccordion("Profile Management");
    }
    if (tabParam === "verification-process") {
      setOverView("verification-process");
      setAccordionData(verificationProcess);
      setTitleAccordion("Verification Process");
    }
    if (tabParam === "privacy-and-security") {
      setOverView("privacy-and-security");
      setAccordionData(privacyAndSecurity);
      setTitleAccordion("Privacy and Security");
    }
    if (tabParam === "support-and-community") {
      setOverView("support-and-community");
      setAccordionData(supportAndCommunity);
      setTitleAccordion("Support and Community");
    }
  }, [location]);

  return (
    <div id="faqsectio" className="bg-[#F9FAFB]">
      {/* <SearchFaqs search={tabQueryParam} /> */}

      <div className="flex flex-col justify-between max-w-[1366px] m-auto px-12 py-10 max-md:px-5">
        <div className="font-bold text-[#111827] text-[36px] max-md:text-[24px]">
          Frequently Asked Questions
        </div>
        <div className="container-1 w-full mt-10 max-md:mt-5">
          <div className="flex items-center gap-2 font-bold text-devRegDarkBlue text-[32px] max-md:text-[20px]">
            <IoChevronBack onClick={() => {
              navigate(`/faq#faqsection`, { replace: true });
              setTimeout(() => {
                document.getElementById("categorySection")?.scrollIntoView({ behavior: "smooth" });
              }, 100);
            }}
              className="cursor-pointer" />
            {titleAccordion}
          </div>

          <div className="w-full">
            {/* NEW VERSION */}
            {/* <FaqAccordion items={accordionData} query={tabQueryParam} /> */}

            {/* OLD VERSION */}
            <FaqAccordion items={accordionData} query={tabQueryParam} activeIndex={activeIndex}
              setActiveIndex={setActiveIndex} />
          </div>
        </div>
      </div>
      <BecomeRecognized />
    </div>
  );
}

export default CategoryFaqs;
