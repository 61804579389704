import React, { useEffect, useState, useRef } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";

function FaqAccordion({ items, query, activeIndex, setActiveIndex }) {
  const [searchQuery, setSearchQuery] = useState(query);
  const contentRefs = useRef([]);

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  const onTitleClick = (item, index) => {
    if (item.title === searchQuery) {
      setSearchQuery(null);
      return;
    }
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      <div className="accordion w-full mt-3">
        {items.map((item, index) => (
          <div
            key={index}
            className="accordion-item cursor-pointer rounded-lg border border-[#E3E3E3] p-4 mt-2 max-md:p-3"
          >
            <div
              className={`accordion-title ${
                index === activeIndex ? "active" : ""
              } flex justify-between items-center`}
              onClick={() => onTitleClick(item, index)}
            >
              <div className="text-[#111827] font-semibold text-[16px]">
                {item.title}
              </div>
              <div>
                {index === activeIndex || searchQuery === item.title ? (
                  <MdKeyboardArrowDown size={25} color="#6B7280" />
                ) : (
                  <MdKeyboardArrowRight size={25} color="#6B7280" />
                )}
              </div>
            </div>
            <div
              ref={(el) => (contentRefs.current[index] = el)}
              className={`accordion-content overflow-hidden transition-max-height duration-500 ease ${
                index === activeIndex || searchQuery === item.title
                  ? "max-h-[1000px] opacity-100"
                  : "max-h-0 opacity-0"
              }`}
              style={{
                maxHeight:
                  index === activeIndex || searchQuery === item.title
                    ? `${contentRefs.current[index]?.scrollHeight}px`
                    : "0px",
              }}
            >
              <div className="text-sm14 text-[#6B7280] max-sm:pr-8 max-w-[90%] my-2">
                {item.content}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default FaqAccordion;
