import React from "react";

function MissionVision() {
  return (
    <div className="max-w-main px-10 m-auto bg-[#FAFAFA] rounded-2xl mt-20 pb-20 max-md:px-3 max-md:mt-10 max-md:pb-14">
      <div className="font-bold text-2xl sm:text-3xl lg:text-[30px] text-devRegDarkBlue mb-8 max-md:px-3">
        Mission and Vision
      </div>
      <div className="flex justify-between gap-12 max-md:px-3 items-start max-[900px]:flex-col max-md:w-full max-[900px]:items-center">
        <div className="flex w-1/2 flex-col gap-5 bg-white rounded-lg p-6 border border-[#E5E7EB] max-[900px]:w-full">
          <img
            src="/AboutUsLogo-1.svg"
            alt="AboutUsLogo"
            className="w-[90px] h-[90px]"
          />
          <div>
            <div className="font-bold text-[24px] text-devRegDarkBlue">The Mission</div>
            <p className="text-sm16 mt-4 max-w-full text-devRegDarkBlue">
              To empower software developers by fostering trust, integrity, and transparency. We aim to create a secure environment where developers can showcase their skills and clients can confidently engage, knowing every professional has been thoroughly verified. By combating fraud and promoting honesty, DevReg.org is dedicated to shaping a safer and more reliable digital workspace for all.
            </p>
          </div>
        </div>
        <div className="flex w-1/2 flex-col gap-5 bg-white rounded-lg p-6 border border-[#E5E7EB] max-[900px]:w-full">
          <img
            src="/AboutUsLogo-2.svg"
            alt="AboutUsLogo"
            className="w-[90px] h-[90px]"
          />
          <div>
            <div className="font-bold text-[24px] text-devRegDarkBlue">The Vision</div>
            <p className="text-sm16 mt-4 max-w-full text-devRegDarkBlue">
              To be the global leader in trusted identity verification for software developers, creating a secure and transparent ecosystem where professionals and clients can collaborate without fear of scams or fraud. We envision a future where trust and authenticity define every digital interaction, empowering developers to thrive and clients to hire with confidence.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissionVision;