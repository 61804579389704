import React from "react";
import { useNavigate } from "react-router-dom";

function BecomeRecognized() {
  const navigate = useNavigate();
  
  return (
    <div className="flex flex-col items-center px-20 max-md:px-5 py-12 mt-28 max-md:mt-0 w-full bg-devRegDarkBlue">
      <div className="flex flex-wrap gap-10 items-center w-full px-12 max-md:px-0 max-w-[1366px] max-md:max-w-full max-md:text-center">
        <div className="flex flex-col flex-1 shrink justify-center m-auto self-stretch my-auto text-white basis-10 min-w-[240px] max-md:max-w-full">
          <div className="text-3xl font-bold max-md:max-w-main">
            Ready to become a recognised, trusted, and sought-after professional
            in the developer world?
          </div>
          <div className="mt-2 text-base leading-6 max-md:max-w-main">
            Transform your career and stand out in the tech industry. Showcase
            your expertise, and attract top opportunities. Start your journey to
            becoming a recognized, trusted, and in-demand professional today.
          </div>
        </div>
        <div className="flex flex-col ml-5 max-md:ml-0 max-md:w-full">
            <div
              className={`bg-[#E0E7FF] justify-center self-stretch max-sm:m-auto font-semibold px-6 py-2 my-auto text-xl max-sm:text-sm16 text-devRegDarkBlue rounded-full max-md:px-5 text-center cursor-pointer max-md:w-full`}
              onClick={() => {
                navigate("/register");
                window.scrollTo(0, 0);
              }}
            >
              Register Now!
            </div>
          </div>
      </div>
    </div>
  );
}

export default BecomeRecognized;
