import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import PhoneNumber from "../../../Registration/components/PhoneNumber";
import {
  checkPassword,
  sendOtpPhone,
  verifyOtpPhone,
  sendOtpNewPhone,
  verifyOtpNewPhone,
} from "../../../../api/apiCall.js";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";

function ChangeNumber() {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorOtp, setErrorOtp] = useState(null);
  const [otpError, setOtpError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [resending, setResending] = useState(false);
  const [currentView, setCurrentView] = useState("verify-pass");
  const [password, setPassword] = useState("");
  const [phoneToken, setPhoneToken] = useState("");
  const [OtpOne, setOtpOne] = useState(null);
  const [OtpTwo, setOtpTwo] = useState(null);
  const [OtpThree, setOtpThree] = useState(null);
  const [OtpFour, setOtpFour] = useState(null);
  const [OtpFive, setOtpFive] = useState(null);
  const [OtpSix, setOtpSix] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [registrationPhoneNumber, setRegistrationPhoneNumber] = useState("");
  const [OtpOneNP, setOtpOneNP] = useState(null);
  const [OtpTwoNP, setOtpTwoNP] = useState(null);
  const [OtpThreeNP, setOtpThreeNP] = useState(null);
  const [OtpFourNP, setOtpFourNP] = useState(null);
  const [OtpFiveNP, setOtpFiveNP] = useState(null);
  const [OtpSixNP, setOtpSixNP] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const otpRef1 = useRef(null);
  const otpRef2 = useRef(null);
  const otpRef3 = useRef(null);
  const otpRef4 = useRef(null);
  const otpRef5 = useRef(null);
  const otpRef6 = useRef(null);

  const handleChange = (e, setOtp, nextRef) => {
    const value = e.target.value;
    setOtp(value);

    if (value && nextRef) {
      nextRef.current.focus();
    }
  };

  useEffect(() => {
    if (OtpOne && OtpTwo && OtpThree && OtpFour && OtpFive && OtpSix) {
      handleVerifyOtpPhone();
    }
  }, [OtpOne, OtpTwo, OtpThree, OtpFour, OtpFive, OtpSix]);

  useEffect(() => {
    if (OtpOneNP && OtpTwoNP && OtpThreeNP && OtpFourNP && OtpFiveNP && OtpSixNP) {
      handleVerifyOtpNewPhone();
    }
  }, [OtpOneNP, OtpTwoNP, OtpThreeNP, OtpFourNP, OtpFiveNP, OtpSixNP]);

  const handleCheckPassword = async () => {
    setIsLoading(true);
    setPasswordError(false);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          password: password
        }
      }
      const response = await checkPassword(data);
      if (response.status === 200) {
        setErrorMessage(null);
        setCurrentView("send-otp-to-number")
      }
    }
    catch (error) {
      setErrorMessage(error.response.data.message);
      setPasswordError(true);
      console.error("Error checking password: ", error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const maskPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== 'string') return 'Invalid phone number';
    if (phoneNumber.length < 3) return phoneNumber;
    if (phoneNumber === null) return '';
    const lastThreeDigits = phoneNumber.slice(-3);
    const maskedPart = '*'.repeat(phoneNumber.length - 3);
    const maskedPhoneNumber = maskedPart + lastThreeDigits;
    return maskedPhoneNumber;
  }

  const handleSendOtp = async () => {
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: user.email,
          phone_number: user.phone_number
        }
      }
      const response = await sendOtpPhone(data);
      if (response.status === 200) {
        setCurrentView("verify-otp");
      }
    }
    catch (error) {
      console.error("Error sending otp: ", error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleResendOtp = async () => {
    setResending(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: user.email,
          phone_number: user.phone_number
        }
      }
      const response = await sendOtpPhone(data);
      if (response.status === 200) {
        startCountdown();
        setCurrentView("verify-otp");
      }
    }
    catch (error) {
      console.error("Error resending otp: ", error);
    }
    finally {
      setResending(false);
    }
  }

  const startCountdown = () => {
    setCountdown(30);
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  }

  const handleVerifyOtpPhone = async () => {
    setOtpError(false);
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          otp: `${OtpOne}${OtpTwo}${OtpThree}${OtpFour}${OtpFive}${OtpSix}`,
          email: user.email,
          is_change_phone_number: 1,
        }
      }
      console.log(data.payloads);
      const response = await verifyOtpPhone(data);
      if (response.status === 200) {
        setPhoneToken(response.data.token);
        setErrorOtp(null);
        setCurrentView("input-new-number");
      }
    }
    catch (error) {
      console.error("Error verifying OTP: ", error);
      setOtpError(true);
      setErrorOtp(error.response.data.message);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handlePhoneNumberChange = (phoneNumber) => {
    setRegistrationPhoneNumber(phoneNumber);
  }

  const handleSendOtpNewPhone = async () => {
    if (!registrationPhoneNumber) {
      setIsPhoneValid(false);
      return;
    }

    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          phone_number: '+' + registrationPhoneNumber,
          token: phoneToken,
        }
      }
      const response = await sendOtpNewPhone(data);
      if (response.status === 200) {
        // setCurrentView("verify-new-otp");
        setErrorOtp(null);
        localStorage.setItem('account_updated', true);
        user.phone_number = '+' + registrationPhoneNumber;
        localStorage.setItem('user', JSON.stringify(user));
        setCurrentView("success");
      }
    }
    catch (error) {
      console.error("Error sending otp to new phone: ", error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleResendOtpNewPhone = async () => {
    setResending(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          phone_number: '+' + registrationPhoneNumber,
          email: user.email,
        }
      }
      const response = await sendOtpPhone(data);
      if (response.status === 200) {
        startCountdown();
        setCurrentView("verify-new-otp");
      }
    }
    catch (error) {
      console.error("Error sending otp to new phone: ", error);
    }
    finally {
      setResending(false);
    }
  }

  const handleVerifyOtpNewPhone = async () => {
    setOtpError(false);
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          otp: `${OtpOneNP}${OtpTwoNP}${OtpThreeNP}${OtpFourNP}${OtpFiveNP}${OtpSixNP}`,
          email: user.email,
          is_change_phone_number: 1,
        }
      }
      console.log(data.payloads);
      const response = await verifyOtpNewPhone(data);
      if (response.status === 200) {
        setErrorOtp(null);
        localStorage.setItem('account_updated', true);
        setCurrentView("success");
      }
    }
    catch (error) {
      console.error("Error verifying OTP: ", error);
      setOtpError(true);
      setErrorOtp(error.response.data.message);
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <div className=" min-h-[87vh]">
      <div className="w-full px-5 mt-6 max-md:px-3 max-md:mt-0">
        {/* =================== Change Number =================== */}
        {currentView === "verify-pass" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Phone</h1>
            <hr className="border-t border-gray-200" />
            <div className="px-6 pt-6">
              <label htmlFor="password" className="block text-gray-700 font-medium mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  className={`w-full px-4 py-3 border ${passwordError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
                </span>
              </div>
              {errorMessage && (
                <small className="text-red-600">{errorMessage}</small>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <button
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={handleCheckPassword}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        )}
        {/* ============== Continue to send otp ================ */}
        {currentView === "send-otp-to-number" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Phone</h1>
            <hr className="border-t border-gray-200" />
            <div className="flex flex-col gap-4 mx-auto tracking-tight leading-5 px-6 py-6 w-full max-md:mt-10 max-md:max-w-full">
              <span className="text-gray-600">An OTP (One-Time Passcode) will be sent to the registered phone
                number ending in {maskPhoneNumber(user.phone_number)}</span>
              <span className="text-gray-700 font-semibold">Would you like to continue?</span>
            </div>
            <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                No
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={() => handleSendOtp()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : "Yes, Send me OTP"}
              </div>
            </div>
          </div>
        )}
        {/* ============== SendOtp ================ */}
        {currentView === "verify-otp" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Phone</h1>
            <hr className="border-t border-gray-200" />
            <div className="pt-6 px-6 text-base text-black text-opacity-70 w-full">
              Enter OTP (One-Time Passcode) we've sent to number ending in{" "}
              <span className="font-bold text-opacity-100">{maskPhoneNumber(user.phone_number)}</span>
            </div>
            <div className="flex gap-4 justify-start max-w-full px-6 mt-5 mb-2 max-md:gap-0 max-md:justify-between">
              <input ref={otpRef1} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpOne, otpRef2)} />
              <input ref={otpRef2} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpTwo, otpRef3)} />
              <input ref={otpRef3} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpThree, otpRef4)} />
              <input ref={otpRef4} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpFour, otpRef5)} />
              <input ref={otpRef5} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpFive, otpRef6)} />
              <input ref={otpRef6} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpSix, null)} />
            </div>
            {errorOtp && (
              <small className="text-red-600 px-6">{errorOtp}</small>
            )}
            <div className="text-base text-black text-opacity-80 px-6 mt-3 flex gap-2 items-center">
              Did not received the code?
              {!resending ? (
                <button className={`text-[#0082ba] font-bold ${countdown > 0
                  ? "opacity-50" : ""
                  }`} disabled={countdown > 0} onClick={() => handleResendOtp()}>Resend Code
                  {countdown > 0 && (
                    <span> ({countdown}s)</span>
                  )}
                </button>
              ) : (
                <div className="w-[17px] h-[17px] border-2 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-2 px-8 py-6 max-md:flex-col max-md:px-6">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={() => handleVerifyOtpPhone()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : "Apply"}
              </div>
            </div>
          </div>
        )}
        {/* ============== New Number ================ */}
        {currentView === "input-new-number" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Phone</h1>
            <hr className="border-t border-gray-200" />
            <div className="px-6 pt-6">
              <label htmlFor="newPhoneNumber" className="block text-gray-700 font-medium mb-2">
                Enter New Phone Number
              </label>
              <PhoneNumber onPhoneNumberChange={handlePhoneNumberChange} />
              {!isPhoneValid && (
                <small className="text-red-500">Phone Number is required.</small>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={() => handleSendOtpNewPhone()}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : (
                  "Continue"
                )}
              </div>
            </div>
          </div>
        )}
        {/* ============== Apply new otp ================ */}
        {currentView === "verify-new-otp" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Phone</h1>
            <hr className="border-t border-gray-200" />
            <div className="pt-6 px-6 text-base text-black text-opacity-70 w-full">
              Enter OTP (One-Time Passcode) we've sent to number ending in{" "}
              <span className="font-bold text-opacity-100"> {maskPhoneNumber(registrationPhoneNumber)}</span>
            </div>
            <div className="flex gap-4 justify-start max-w-full px-6 mt-5 mb-2 max-md:gap-0 max-md:justify-between">
              <input ref={otpRef1} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpOneNP, otpRef2)} />
              <input ref={otpRef2} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpTwoNP, otpRef3)} />
              <input ref={otpRef3} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpThreeNP, otpRef4)} />
              <input ref={otpRef4} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpFourNP, otpRef5)} />
              <input ref={otpRef5} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpFiveNP, otpRef6)} />
              <input ref={otpRef6} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpSixNP, null)} />
            </div>
            {errorOtp && (
              <small className="text-red-600 px-6">{errorOtp}</small>
            )}
            <div className="text-base text-black text-opacity-80 px-6 mt-3 flex gap-2 items-center">
              Did not received the code?
              {!resending ? (
                <button className={`text-[#0082ba] font-bold ${countdown > 0
                  ? "opacity-50" : ""
                  }`} disabled={countdown > 0} onClick={() => handleResendOtpNewPhone()}>Resend
                  {countdown > 0 && (
                    <span> ({countdown}s)</span>
                  )}
                </button>
              ) : (
                <div className="w-[17px] h-[17px] border-2 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-2 px-8 py-6 max-md:flex-col max-md:px-6">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-ful"
                onClick={() => handleVerifyOtpNewPhone()}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : (
                  "Apply"
                )}
              </div>
            </div>
          </div>
        )}
        {/* ============== success ================ */}
        {currentView === "success" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Phone</h1>
            <hr className="border-t border-gray-200" />
            <div className="flex justify-start items-center px-6 pt-8 pb-4 max-w-full text-xl font-bold tracking-tight leading-5 text-blue-900">
              <div className="flex gap-3">
                <FaCheckCircle size={20} className="text-[#0082ba]" />
                <div>Phone Number Updated</div>
              </div>
            </div>
            <div className="text-base leading-5 text-neutral-600 max-md:max-w-full px-6">
              You successfully changed your Phone Number
            </div>
            <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col">
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Continue
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChangeNumber;
