import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import App from './App';

const LocaleProvider = () => {
	const [locale, setLocale] = useState('en'); // Default locale

	useEffect(() => {
		const preventSelection = (event) => {
			const target = event.target;
			if (
			  target.tagName === 'INPUT' || 
			  target.tagName === 'TEXTAREA' || 
			  target.isContentEditable
			) {
			  return;
			}
			event.preventDefault();
		};
		const preventCopyCut = (event) => {
			const target = event.target;
			if (
			  target.tagName === 'INPUT' || 
			  target.tagName === 'TEXTAREA' || 
			  target.isContentEditable
			) {
			  return;
			}
			event.preventDefault();
		};
		// const handleContextMenu = (event) => {
		// 		event.preventDefault(); 
		// };
		// const disableShortcuts = (event) => {
		// 	if (
		// 		(event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J' || event.key === 'C')) || 
		// 		event.key === 'F12'
		// 	) {
		// 		event.preventDefault();
		// 		alert("Inspect element is disabled.");
		// 	}
		// };
	
		document.addEventListener('selectstart', preventSelection);
		document.addEventListener('copy', preventCopyCut);
		document.addEventListener('cut', preventCopyCut);
		// document.addEventListener('contextmenu', handleContextMenu);
    	// document.addEventListener('keydown', disableShortcuts);
	
		return () => {
			document.removeEventListener('selectstart', preventSelection);
			document.removeEventListener('copy', preventCopyCut);
			document.removeEventListener('cut', preventCopyCut);
			// document.removeEventListener('contextmenu', handleContextMenu);
      		// document.removeEventListener('keydown', disableShortcuts);
		};
	  }, []);

	useEffect(() => {
		// You can determine the locale dynamically here, e.g., from user settings or browser settings
		// const intl = useIntl(); const locale = intl.locale;
		const userLocale = navigator.language.split('-')[0] || 'en';
		setLocale(userLocale);
	}, []);

	return (
		<IntlProvider locale={locale}>
			<App />
		</IntlProvider>
	);
};

export default LocaleProvider;