import React from "react";
import TopBanner from "../components/TopBanner";
import Header from "./Header";
import MissionVision from "./MissionVision";
import OurVision from "./OurVision";
import DevregEssensial from "./DevregEssensial";
import BecomeRecognized from "../components/BecomeRecognized";
import MeetTheSaffs from "./MeetTheStaffs";

function AboutUsMain({ teamMembers, loading }) {
  return (
    <div className="bg-[#F9FAFB]">
      <div className="max-md:hidden">
        <TopBanner imgBg={"/AboutUsHeader-BG.png"} center={false} />
      </div>
      <div className="hidden max-md:block">
        <TopBanner imgBg={"/AboutUsHeader-BGMobile.png"} center={true} class="z-1" />
      </div>
      <Header />
      <MissionVision />
      <OurVision />
      <DevregEssensial />
      <BecomeRecognized />
      <MeetTheSaffs teamMembers={teamMembers} loading={loading} />
    </div>
  );
}

export default AboutUsMain;