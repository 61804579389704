import React from "react";
import TopBanner from "../components/TopBanner";
import Section1 from "./Section1";
import Whybenefit from "./Whybenefit";
import BecomeRecognized from "../components/BecomeRecognized";

function BenefitPageMain() {
  return (
    <div className="bg-[#F9FAFB]">
      <div className="max-md:hidden">
        <TopBanner imgBg={"/BenefitPageImg-Header.png"} center={true} />
      </div>
      <div className="hidden max-md:block">
        <TopBanner imgBg={"/BenefitPageImg-HeaderMobile.png"} center={true} />
      </div>
      <Section1 />
      <Whybenefit />
      <BecomeRecognized />
    </div>
  );
}

export default BenefitPageMain;