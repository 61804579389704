import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setIsLoading } from "../../../../actions/index.js";
import {
  checkPassword,
  sendOtpEmail,
  verifyOtpEmail,
  sendOtpNewEmail,
  verifyOtpNewEmail,
  logout,
} from "../../../../api/apiCall.js";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";

function ChangeEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorOtp, setErrorOtp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [emailToken, setEmailToken] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [resending, setResending] = useState(false);
  const [currentView, setCurrentView] = useState("verify-pass-email");
  // const [currentView, setCurrentView] = useState("input-new-email");
  const [password, setPassword] = useState("");
  const [resendOtpLink, setResendOtpLink] = useState("");
  const [OtpOne, setOtpOne] = useState(null);
  const [OtpTwo, setOtpTwo] = useState(null);
  const [OtpThree, setOtpThree] = useState(null);
  const [OtpFour, setOtpFour] = useState(null);
  const [OtpFive, setOtpFive] = useState(null);
  const [OtpSix, setOtpSix] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [newEmail, setNewEmail] = useState("");
  const [OtpOneNE, setOtpOneNE] = useState(null);
  const [OtpTwoNE, setOtpTwoNE] = useState(null);
  const [OtpThreeNE, setOtpThreeNE] = useState(null);
  const [OtpFourNE, setOtpFourNE] = useState(null);
  const [OtpFiveNE, setOtpFiveNE] = useState(null);
  const [OtpSixNE, setOtpSixNE] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);

  // Refs for OTP input fields
  const otpRef1 = useRef(null);
  const otpRef2 = useRef(null);
  const otpRef3 = useRef(null);
  const otpRef4 = useRef(null);
  const otpRef5 = useRef(null);
  const otpRef6 = useRef(null);

  // Function to handle input change and move to next field
  const handleChange = (e, setOtp, nextRef) => {
    const value = e.target.value;
    setOtp(value);

    // Move to next input field if a value is entered
    if (value && nextRef) {
      nextRef.current.focus();
    }
  };

  useEffect(() => {
    if (OtpOne && OtpTwo && OtpThree && OtpFour && OtpFive && OtpSix) {
      handleVerifyOtpEmail();
    }
  }, [OtpOne, OtpTwo, OtpThree, OtpFour, OtpFive, OtpSix]);

  useEffect(() => {
    if (OtpOneNE && OtpTwoNE && OtpThreeNE && OtpFourNE && OtpFiveNE && OtpSixNE) {
      handleVerifyOtpNewEmail();
    }
  }, [OtpOneNE, OtpTwoNE, OtpThreeNE, OtpFourNE, OtpFiveNE, OtpSixNE]);

  const handleCheckPassword = async () => {
    setIsLoading(true);
    setPasswordError(false);

    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          password: password
        }
      }
      const response = await checkPassword(data);
      if (response.status === 200) {
        setErrorMessage(null);
        setCurrentView("send-otp-to-email")
      }
    }
    catch (error) {
      setErrorMessage(error.response.data.message);
      setPasswordError(true);
      console.error("Error checking password: ", error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const hideEmail = (email) => {
    const [localPart, domainPart] = email.split('@');
    const lettersToHide = Math.max(localPart.length - 3, 0);
    const hiddenLocalPart = localPart[0] + localPart[1] + '*'.repeat(lettersToHide) + localPart.slice(-1);
    return `${hiddenLocalPart}@${domainPart}`;
  }

  const handleSendOtpEmail = async () => {
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: user.email,
        }
      }
      const response = await sendOtpEmail(data);
      if (response.status === 200) {
        setResendOtpLink(response.resend_otp_link);
        setCurrentView("verify-otp-email");
      }
    }
    catch (error) {
      console.error("Error sending otp: ", error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleResendOtpEmail = async () => {
    setResending(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: user.email,
          phone_number: user.phone_number
        }
      }
      const response = await sendOtpEmail(data);
      if (response.status === 200) {
        setResendOtpLink(response.resend_otp_link);
        startCountdown()
        setCurrentView("verify-otp-email");
      }
    }
    catch (error) {
      console.error("Error resending otp: ", error);
    }
    finally {
      setResending(false);
    }
  }

  const startCountdown = () => {
    setCountdown(30);
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleVerifyOtpEmail = async () => {
    setOtpError(false);
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          otp: `${OtpOne}${OtpTwo}${OtpThree}${OtpFour}${OtpFive}${OtpSix}`,
          email: user.email,
          is_change_email: 1,
        }
      }
      console.log(data.payloads);
      const response = await verifyOtpEmail(data);
      if (response.status === 200) {
        setEmailToken(response.data.token);
        setCurrentView("input-new-email");
        setErrorOtp(null);
      }
    }
    catch (error) {
      console.error("Error verifying OTP: ", error);
      setOtpError(true);
      setErrorOtp(error.response.data.message);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleSendOtpNewEmail = async () => {
    if (!newEmail) {
      setIsEmailValid(false);
      return;
    }

    setIsEmailValid(true);
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          email: newEmail,
          token: emailToken,
        }
      }
      const response = await sendOtpNewEmail(data);
      if (response.status === 200) {
        // setResendOtpLink(response.resend_otp_link);
        // setCurrentView("verify-new-otp");
        setErrorOtp(null);
        user.email = newEmail;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('account_updated', true);
        setCurrentView("success");
      }
    }
    catch (error) {
      console.error("Error sending otp to new email: ", error);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleResendOtpNewEmail = async () => {
    setResending(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          new_email: newEmail,
          new_email_confirmation: newEmail
        }
      }
      const response = await sendOtpNewEmail(data);
      if (response.status === 200) {
        setResendOtpLink(response.resend_otp_link);
        startCountdown();
        setCurrentView("verify-new-otp");
      }
    }
    catch (error) {
      console.error("Error sending otp to new email: ", error);
    }
    finally {
      setResending(false);
    }
  }

  const handleVerifyOtpNewEmail = async () => {
    setOtpError(false);
    setIsLoading(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          otp: `${OtpOneNE}${OtpTwoNE}${OtpThreeNE}${OtpFourNE}${OtpFiveNE}${OtpSixNE}`,
          new_email: newEmail
        }
      }
      console.log(data.payloads);
      const response = await verifyOtpNewEmail(data);
      if (response.status === 200) {
        setErrorOtp(null);
        localStorage.setItem('account_updated', true);
        setCurrentView("success");
      }
    }
    catch (error) {
      console.error("Error verifying OTP: ", error);
      setOtpError(true);
      setErrorOtp(error.response.data.message);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleLogout = async () => {
    localStorage.clear();
    dispatch(setIsLoggedIn(false));
    navigate("/login")
  };

  return (
    <div className="min-h-[89vh]">
      <div className="w-full px-5 mt-6 max-md:px-3 max-md:mt-0">
        {/* =================== Change Email =================== */}
        {currentView === "verify-pass-email" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Email</h1>
            <hr className="border-t border-gray-200" />
            <div className="px-6 pt-6">
              <label htmlFor="password" className="block text-gray-700 font-medium mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  className={`w-full px-4 py-3 border ${passwordError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiOutlineEyeInvisible size={20} /> : <AiOutlineEye size={20} />}
                </span>
              </div>
              {errorMessage && (
                <small className="text-red-600">{errorMessage}</small>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={handleCheckPassword}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : "Continue"}
              </div>
            </div>
          </div>
        )}
        {/* ============== Continue to send otp ================ */}
        {currentView === "send-otp-to-email" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Email</h1>
            <hr className="border-t border-gray-200" />
            <div className="flex flex-col gap-4 mx-auto tracking-tight leading-5 px-6 py-6 w-full max-md:mt-10 max-md:max-w-full">
              <span className="text-gray-600">An OTP (One-Time Passcode) will be sent to email address {hideEmail(user.email)}</span>
              <span className="text-gray-700 font-semibold">Would you like to continue?</span>
            </div>
            <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                No
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={() => handleSendOtpEmail()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : "Yes, Send me OTP"}
              </div>
            </div>
          </div>
        )}
        {/* ============== SendOtp ================ */}
        {currentView === "verify-otp-email" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Email</h1>
            <hr className="border-t border-gray-200" />
            <div className="pt-6 px-6 text-base text-black text-opacity-70 w-full">
              Enter OTP (One-Time Passcode) we've sent to email address{" "}
              <span className="font-bold text-opacity-100"> {hideEmail(user.email)}</span>
            </div>
            <div className="flex gap-4 justify-start max-w-full px-6 mt-5 mb-2 max-md:gap-0 max-md:justify-between">
              <input ref={otpRef1} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpOne, otpRef2)} />
              <input ref={otpRef2} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpTwo, otpRef3)} />
              <input ref={otpRef3} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpThree, otpRef4)} />
              <input ref={otpRef4} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpFour, otpRef5)} />
              <input ref={otpRef5} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpFive, otpRef6)} />
              <input ref={otpRef6} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpSix, null)} />
            </div>
            {errorOtp && (
              <small className="text-red-600 px-6">{errorOtp}</small>
            )}
            <div className="text-base text-black text-opacity-80 px-6 mt-3 flex gap-2 items-center">
              Did not received the code?
              {!resending ? (
                <button className={`text-[#0082ba] font-bold ${countdown > 0
                  ? "opacity-50" : ""
                  }`} disabled={countdown > 0} onClick={() => handleResendOtpEmail()}>Resend Code
                  {countdown > 0 && (
                    <span> ({countdown}s)</span>
                  )}
                </button>
              ) : (
                <div className="w-[17px] h-[17px] border-2 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-2 px-8 py-6 max-md:flex-col max-md:px-6">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={() => handleVerifyOtpEmail()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : "Apply"}
              </div>
            </div>
          </div>
        )}
        {/* ============== New Email ================ */}
        {currentView === "input-new-email" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Email</h1>
            <hr className="border-t border-gray-200" />
            <div className="px-6 pt-6">
              <label htmlFor="newEmail" className="block text-gray-700 font-medium mb-2">
                Enter New Email
              </label>
              <input
                id="newEmail"
                type="email"
                className={`w-full px-4 py-3 border ${!isEmailValid ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Email Address"
                onChange={(e) => setNewEmail(e.target.value)}
              />
              {!isEmailValid && (
                <small className="text-red-500">Email is required.</small>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={handleSendOtpNewEmail}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : (
                  "Continue"
                )}
              </div>
            </div>
          </div>
        )}
        {/* ============== Apply new otp email ================ */}
        {currentView === "verify-new-otp" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Email</h1>
            <hr className="border-t border-gray-200" />
            <div className="pt-6 px-6 text-base text-black text-opacity-70 w-full">
              Enter OTP (One-Time Passcode) we've sent to email address{" "}
              <span className="font-bold"> {hideEmail(newEmail)}</span>
            </div>
            <div className="flex gap-4 justify-start max-w-full px-6 mt-5 mb-2 max-md:gap-0 max-md:justify-between">
              <input ref={otpRef1} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpOneNE, otpRef2)} />
              <input ref={otpRef2} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpTwoNE, otpRef3)} />
              <input ref={otpRef3} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpThreeNE, otpRef4)} />
              <input ref={otpRef4} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpFourNE, otpRef5)} />
              <input ref={otpRef5} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpFiveNE, otpRef6)} />
              <input ref={otpRef6} type="text" className={`text-center shrink-0 p-2.5 border h-[45px] w-[45px] ${otpError ? "border-red-500" : "border-gray-300"} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`} maxLength={1} onChange={(e) => handleChange(e, setOtpSixNE, null)} />
            </div>
            {errorOtp && (
              <small className="text-red-600 px-6">{errorOtp}</small>
            )}
            <div className="text-base text-black text-opacity-80 px-6 mt-3 flex gap-2 items-center">
              Did not received the code?
              {!resending ? (
                <button className={`text-[#0082ba] font-bold ${countdown > 0
                  ? "opacity-50" : ""
                  }`} disabled={countdown > 0} onClick={() => handleResendOtpNewEmail()}>Resend Code
                  {countdown > 0 && (
                    <span> ({countdown}s)</span>
                  )}
                </button>
              ) : (
                <div className="w-[17px] h-[17px] border-2 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-2 px-8 py-6 max-md:flex-col max-md:px-6">
              <div
                className="px-7 py-3 bg-[#e0e7ff] text-devRegDarkBlue rounded-full font-semibold text-center cursor-pointer"
                onClick={() => navigate("/dashboard/account-settings")}
              >
                Cancel
              </div>
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={() => handleVerifyOtpNewEmail()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="w-[20px] h-[20px] border-2 border-t-transparent border-white-500 rounded-full animate-spin mx-auto"></div>
                ) : "Apply"}
              </div>
            </div>
          </div>
        )}
        {/* ============== success ================ */}
        {currentView === "success" && (
          <div className="flex flex-col max-w-[800px] m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
            <h1 className="text-lg font-bold text-gray-800 px-6 py-6">Change Email</h1>
            <hr className="border-t border-gray-200" />
            <div className="flex justify-start items-center px-6 pt-8 pb-4 max-w-full text-xl font-bold tracking-tight leading-5 text-blue-900">
              <div className="flex gap-3">
                <FaCheckCircle size={20} className="text-[#0082ba]" />
                <div>Email Address Updated</div>
              </div>
            </div>
            <div className="text-base leading-5 text-neutral-600 max-md:max-w-full px-6">
              You successfully changed your Email Address. Please re-login to continue.
            </div>
            <div className="flex justify-end gap-4 mt-2 px-6 py-6 max-md:flex-col">
              <div
                className="py-3 bg-blue-900 font-semibold w-[180px] text-center text-white rounded-full cursor-pointer max-md:w-full"
                onClick={handleLogout}
              >
                Re-login
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChangeEmail;
