import React, { useState, useEffect } from 'react'
import { FaMagnifyingGlass } from "react-icons/fa6";
import {
  generalInformation,
  registrationAndMembership,
  profileManagement,
  verificationProcess,
  privacyAndSecurity,
  supportAndCommunity,
} from "./AccordionFaqs";
import { Link } from 'react-router-dom';

const SearchFaqs = ({ search = "" }) => {
    const [searchInput, setSearchInput] = useState(search);
    const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const faqs = [
        ...generalInformation.map((item) => ({ ...item, category: "general-information" })),
        ...registrationAndMembership.map((item) => ({ ...item, category: "registration-and-membership" })),
        ...profileManagement.map((item) => ({ ...item, category: "profile-management" })),
        ...verificationProcess.map((item) => ({ ...item, category: "verification-process" })),
        ...privacyAndSecurity.map((item) => ({ ...item, category: "privacy-and-security" })),
        ...supportAndCommunity.map((item) => ({ ...item, category: "support-and-community" })),
    ];

    const handleSearchFAQ = (e) => {
        setSearchInput(e.target.value);
        const lowerCaseSearchTerm = e.target.value.toLowerCase();
        setSuggestions(faqs
        .filter((faq) => faq.title.toLowerCase().includes(lowerCaseSearchTerm))
        .map((faq) => ({ title: faq.title, content: faq.content, category: faq.category})));
    };

    useEffect(() => {
        if(searchInput) setIsSuggestionOpen(true);
        else setIsSuggestionOpen(false);
    }, [searchInput]);

    useEffect(() => {
        setIsSuggestionOpen(false);
    }, [])

  return (
    <div className="bg-devRegDarkBlue flex flex-col py-20 items-center justify-center max-md:px-5 max-md:py-16">
        <div className="text-white font-bold text-[33px] pb-4">
          How we can help you
        </div>
        <div className="relative w-full max-w-[600px]">
          <FaMagnifyingGlass className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          <input
            type="search"
            value={searchInput}
            placeholder="Search for queries"
            onChange={(e) => handleSearchFAQ(e)}
            className="w-full rounded-md pl-10 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {isSuggestionOpen && (
            <div className="absolute bg-white rounded-[10px] w-full border flex flex-col">
              {suggestions.length === 0 && <p className="text-center p-2">No result for "{searchInput}"</p>}
              {suggestions.map((suggestion, index) => (
                <Link 
                    key={index} 
                    className="p-2 hover:bg-gray-300 cursor-pointer" 
                    to={`/faq/category?tab=${suggestion.category}&query=${suggestion.title}`} 
                    onClick={() => setIsSuggestionOpen(false)}
                >
                  {suggestion.title}
                </Link>
              ))}
            </div>
          )}
          
        </div>
      </div>
  )
}

export default SearchFaqs
