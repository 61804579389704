import * as React from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This is important for styling
import TopBanner from "../components/TopBanner";
import { HiOutlineLightningBolt } from "react-icons/hi";

function AboutProfile() {
    const [isOpen, setIsOpen] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);

    const images = [
        "/Devreg_A0.jpg",
    ];

    return (
        <div className="bg-[#F9FAFB]">
            <TopBanner imgBg={"/AboutProfileHeader.png"} center={false} />
            <div className="flex overflow-hidden flex-col justify-center items-center pb-16">
                {/* <div className="flex overflow-hidden w-full justify-center py-16 px-10 bg-blue-900 min-h-[254px]">
                <div className="flex flex-col max-w-main w-full px-10 max-md:px-3">
                    <div className="max-sm:text-sm20 max-lg:text-[24px] max-md:text-center text-[33px] font-bold text-white">
                        ABOUT PROFILE
                    </div>
                    <div className="mt-6 max-sm:text-center text-white font-light text-sm15 max-sm:mt-2">
                        This sample profile showcases what your completed profile will look
                        like. Simply follow the prompts provided on the side of the screen;{" "}
                        <br />
                        they will guide you through each step of creating your profile,
                        providing helpful tips on what information to include and how to
                        best present yourself.
                    </div>
                </div>
            </div> */}
                <div className="max-md:px-6 flex flex-col mt-16 max-w-main max-md:mt-10 px-10 items-start max-md:items-start">
                    <div className="flex flex-col">
                        <div className="flex gap-2 justify-center items-center self-start px-3 py-2 text-sm font-medium leading-none text-center text-green-500 bg-green-100 rounded-3xl">
                            <HiOutlineLightningBolt size={18} />
                            <div className="self-stretch my-auto">Devreg Sample Profile</div>
                        </div>
                        <div className="mt-2 text-left font-bold text-2xl md:text-3xl text-devRegDarkBlue">
                            Profile Setup Guide
                        </div>
                        <p className="text-gray-500 mt-2">Click or tap the image to activate zoom mode. Once zoomed in, use your mouse scroll wheel or pinch to zoom further, and click and drag to move around the screen to explore the profile details.</p>
                    </div>
                    <img
                        loading="lazy"
                        src="/Devreg_A0.jpg"
                        className="object-contain w-full rounded-xl cursor-pointer mt-12 max-md:mt-8"
                        onClick={() => {
                            setIsOpen(true);
                        }}
                    />
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + images.length - 1) % images.length)
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % images.length)
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default AboutProfile;
